import { arbitrum } from './chains/arbitrum';
import { avalanche } from './chains/avalanche';
import { base } from './chains/base';
import { bsc } from './chains/bsc';
import { bscTestnet } from './chains/bscTestnet';
import { ethereum } from './chains/ethereum';
import { linea } from './chains/linea';
import { manta } from './chains/manta';
import { optimism } from './chains/optimism';
import { polygon } from './chains/polygon';
import { polygonMumbai } from './chains/polygonMumbai';
import { rootstock } from './chains/rootstock';
import { rootstockTestnet } from './chains/rootstockTestnet';

export const PUBLIC_NODES: {
  [chainId: number]: string[];
} = {
  [arbitrum.id]: [
    // 'https://rpc.ankr.com/arbitrum',
    'https://endpoints.omniatech.io/v1/arbitrum/one/public',
    'https://arb1.arbitrum.io/rpc',
    'https://arbitrum.public-rpc.com',
  ],
  [avalanche.id]: ['https://rpc.ankr.com/avalanche', 'https://api.avax.network/ext/bc/C/rpc'],
  [bsc.id]: [
    // 'https://rpc.ankr.com/bsc',
    'https://endpoints.omniatech.io/v1/bsc/mainnet/public',
    'https://bsc-mainnet.gateway.pokt.network/v1/lb/6136201a7bad1500343e248d',
    'https://1rpc.io/bnb',
    'https://bsc-dataseed3.binance.org',
    'https://bsc-dataseed2.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
    'https://binance.nodereal.io',
    'https://bsc-dataseed4.defibit.io',
  ],
  [bscTestnet.id]: ['https://bsc-testnet-rpc.publicnode.com', 'https://data-seed-prebsc-2-s2.binance.org:8545'],
  [ethereum.id]: [
    'https://ethereum-rpc.publicnode.com',
    'https://api.mycryptoapi.com/eth',
    'https://cloudflare-eth.com',
    'https://eth-mainnet.public.blastapi.io',
  ],
  [optimism.id]: ['https://rpc.ankr.com/optimism', 'https://mainnet.optimism.io/'],
  [polygon.id]: [
    'https://rpc.ankr.com/polygon',
    'https://polygon-rpc.com/',
    'https://rpc-mainnet.matic.network',
    'https://matic-mainnet.chainstacklabs.com',
    'https://rpc-mainnet.maticvigil.com',
    'https://rpc-mainnet.matic.quiknode.pro',
    'https://matic-mainnet-full-rpc.bwarelabs.com',
  ],
  [polygonMumbai.id]: [
    'https://rpc.ankr.com/polygon_mumbai',
    'https://matic-mumbai.chainstacklabs.com',
    'https://rpc-mumbai.maticvigil.com',
    'https://matic-testnet-archive-rpc.bwarelabs.com',
  ],
  [base.id]: [
    'https://rpc.ankr.com/base',
    'https://developer-access-mainnet.base.org',
    'https://1rpc.io/base',
    'https://base.publicnode.com',
    'https://base-mainnet.public.blastapi.io',
  ],
  [manta.id]: ['https://pacific-rpc.manta.network/http', 'https://1rpc.io/manta'],
  [linea.id]: [
    'https://rpc.linea.build',
    'https://1rpc.io/linea',
    'https://linea.blockpi.network/v1/rpc/public',
    'https://linea.drpc.org',
  ],
  [rootstock.id]: ['https://rootstock.drpc.org', 'https://public-node.rsk.co', 'https://mycrypto.rsk.co'],
  [rootstockTestnet.id]: ['https://public-node.testnet.rsk.co', 'https://mycrypto.testnet.rsk.co'],
};
