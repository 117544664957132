import useResizeObserver from '@react-hook/resize-observer';
import classnames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDualAssets } from 'entities/Dual';

import { ForwardIcon } from 'shared/assets';
import { supportedChains } from 'shared/config';
import { CHAIN_INFO } from 'shared/config/chains';
import { useDetectMobileDevice } from 'shared/hooks';
import { Box, Menu, Text } from 'shared/ui';

import { FilterChain } from './FilterChain';
import styles from './FilterChains.module.scss';

type Props = {
  onSelect: (selected?: number) => void;
  selected: number | undefined;
};

export const FilterChains: React.FC<Props> = ({ selected, onSelect }) => {
  const { t } = useTranslation();
  const isMobileDevice = useDetectMobileDevice();

  const assets = useDualAssets();
  const assetsChainMap = useMemo(
    () =>
      assets?.reduce((acc, item) => {
        if (item.chainId) acc[item.chainId] = true;
        return acc;
      }, {} as Record<number, boolean>),
    [assets],
  );
  const assetsChains = supportedChains.filter((chain) => assetsChainMap?.[chain?.id]);

  const handleSelect = (chainId?: number) => {
    onSelect(chainId);
    setOpen(false);
  };

  const handleScreenSize = useCallback((width: number) => {
    if (width < 375) return 2;
    if (width < 400) return 3;
    if (width < 425) return 4;
    if (width < 450) return 5;
    return 6;
  }, []);

  const [isOpen, setOpen] = useState(false);
  const [chainsCount, setChainsCount] = useState(handleScreenSize(window.innerWidth));

  useResizeObserver(document.body, (resize) => setChainsCount(handleScreenSize(resize.borderBoxSize?.[0]?.inlineSize)));

  const selectedChain = CHAIN_INFO[selected || -1];
  const fixedChain = useMemo(() => assetsChains.slice(0, chainsCount - 1), [chainsCount]);

  const lastLineChain = useMemo(
    () =>
      selectedChain && !fixedChain.includes(selectedChain) && chainsCount
        ? selectedChain
        : assetsChains[chainsCount - 1],
    [selectedChain, assetsChains, chainsCount],
  );

  const lineChains = useMemo(
    () => (lastLineChain ? [...fixedChain, lastLineChain] : fixedChain),
    [lastLineChain, chainsCount],
  );
  const extraChains = useMemo(
    () => assetsChains.filter((chain) => !lineChains.includes(chain)),
    [lineChains, chainsCount],
  );

  return (
    <Box className={styles['chains-container']}>
      <Box
        className={classnames(styles['chains-btn-all'], 'animated', {
          hoverable: !isMobileDevice,
          'hoverable-active': undefined === selected,
          [styles['chains-btn-all-active']]: undefined === selected,
        })}
        onClick={() => handleSelect(undefined)}
      >
        <Text style={{ color: 'inherit' }}>{t('main.allChains')}</Text>
      </Box>
      <Box flexDirection="row">
        {lineChains.map((chain) => (
          <FilterChain key={chain.id} selected={selected} onSelect={handleSelect} chain={chain} />
        ))}
      </Box>
      {extraChains.length > 0 && (
        <Menu
          backgroundColor="secondary-04"
          borderColor="secondary-04"
          trigger={
            <Box height="40px" width="34px" alignItems="center" justifyContent="center">
              <Box
                className={classnames(styles['chains-btn-expand'], 'animated', {
                  hoverable: !isMobileDevice,
                })}
                style={{ transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)' }}
              >
                <ForwardIcon color="inherit" strokeWidth="2px" />
              </Box>
            </Box>
          }
          isOpen={isOpen}
          toggle={(state) => setOpen(state)}
          forceCallback
          forceDesktopLayout
          clickable
          hoverable={false}
          showCorner={false}
          py={12}
          px={12}
          minWidth={0}
        >
          <Box display="grid" gridTemplateColumns="32px 32px 32px" zIndex="9999999">
            {extraChains.map((chain) => (
              <FilterChain selected={selected} onSelect={handleSelect} chain={chain} />
            ))}
          </Box>
        </Menu>
      )}
    </Box>
  );
};
