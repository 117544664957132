import { Event, track } from '@rehold-v3/data-layer-client';
import cx from 'classnames';
import { isValidElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { storageSignature } from 'features/Auth';

import { useUserMutation } from 'entities/User';

import { CheckIcon, LanguageEnIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { removeQueryParam } from 'shared/lib';
import { LANGUAGES_ICONS, SUPPORTED_LANGUAGES } from 'shared/locales';
import { Box, Modal, Text } from 'shared/ui';

import styles from './styles.module.scss';

const LANGUAGE_ORDER: Record<string, number> = {
  de: 1,
  fr: 2,
  ja: 3,
  ru: 4,
  en: 5,
  id: 6,
  ko: 7,
  vi: 8,
  es: 9,
  it: 10,
  pt: 11,
  zh: 12,
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const LanguageModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={500}
      closeIcon
      closeIconPaddingX="16px"
      closeIconPaddingY="12px"
      contentPadding="20px 0px 0px 0px"
      header={
        <Box
          alignItems="center"
          justifyContent="center"
          height={48}
          px={24}
          pb={20}
          borderBottom="1px solid"
          borderColor="secondary-02"
        >
          <Text text="app-18-medium">{t('menu.main.language')}</Text>
        </Box>
      }
    >
      <Box className={styles['language-grid']}>
        {Object.keys(SUPPORTED_LANGUAGES).map((lang) => (
          <Item key={lang} lang={lang} onClose={onClose} />
        ))}
      </Box>
    </Modal>
  );
};

const Item: React.FC<{ lang: string; onClose: () => void }> = ({ lang, onClose }) => {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation();
  const { address } = useAccount();

  const { mutateAsync } = useUserMutation();

  const handleChangeLanguage = useCallback(
    (language: string) => {
      track(Event.LANGUAGE_CHANGED, { language });
      changeLanguage(language);
      removeQueryParam('language');

      if (!address) {
        onClose();
        return;
      }

      const signature = storageSignature.get(address);
      if (signature) mutateAsync({ language, signature });
      onClose();
    },
    [changeLanguage, address, mutateAsync],
  );

  if (!SUPPORTED_LANGUAGES[lang]) return;

  const Icon = LANGUAGES_ICONS[lang];
  const icon = Icon && <Icon width={24} height={24} />;
  const isSelected = lang === language;

  return (
    <Box
      order={LANGUAGE_ORDER[lang]}
      className={cx(styles['language-btn'], { [styles.active]: isSelected })}
      onClick={() => handleChangeLanguage(lang)}
    >
      {isValidElement(icon) ? icon : <LanguageEnIcon width={24} height={24} />}
      <Text style={{ color: 'inherit' }}>{SUPPORTED_LANGUAGES[lang]}</Text>
      {isSelected && <CheckIcon width={14} height={14} style={{ position: 'absolute', right: 24, top: 21 }} />}
    </Box>
  );
};
