import { useTheme } from '@mui/material';
import React, { FC, PropsWithChildren, useState, useEffect, memo } from 'react';
import ReactDOM from 'react-dom';

import { Box } from 'shared/ui/Box';

import styles from './Header.module.scss';
import { NavContent } from './NavContent';
import { NavLinks } from './NavLinks';

export const headerHeight = 65;

export const LayoutHeader: FC = memo(() => (
  <LayoutHeaderAnimated>
    <NavLinks />
    <NavContent />
  </LayoutHeaderAnimated>
));

export const LayoutHeaderAnimated: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme();

  const [needBlur, setNeedBlur] = useState(false);

  const needAnimate = needBlur;

  const handleScroll = () => {
    const bodyTopPx = +document.body.style.top.replace('px', '');
    const isBodyFixed = document.body.style.position === 'fixed';
    const isBodyScrollLocked = isBodyFixed && Number.isFinite(bodyTopPx) && bodyTopPx !== 0;

    setNeedBlur(window.scrollY > 0 || isBodyScrollLocked);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const header = ReactDOM.createPortal(
    <div
      className={styles.root}
      style={{
        alignItems: 'center',
        backdropFilter: needAnimate ? 'blur(6px)' : undefined,
        background: needAnimate ? 'rgba(11, 9, 21, 0.9)' : undefined,
        borderBottomWidth: 1,
        borderColor: needAnimate ? colors['secondary-04'] : 'transparent',
        display: 'flex',
        flexDirection: 'row',
        height: 65,
        justifyContent: 'space-between',
        position: 'fixed',
        transitionDuration: '200ms',
        transitionProperty: 'all',
        transitionTimingFunction: 'ease-in-out',
        width: '100%',
        zIndex: 5,
        inset: 0,
      }}
    >
      {children}
    </div>,

    document.body,
  );

  return (
    <Box height={{ default: headerHeight, tablet: headerHeight }}>
      <>{header}</>
    </Box>
  );
};
