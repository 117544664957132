import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { usdtOldAbi } from './abi/custom/usdtOldAbi';
import { AppChain } from './types';

export const ethereum: AppChain<'ethereum'> = {
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  id: 1,
  logo: <CurrencyIcon ticker="eth" size={24} />,
  name: 'Ethereum',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  network: 'ethereum',
  tokenStandard: 'ERC-20',
  renderLogo: (size) => <CurrencyIcon ticker="eth" size={size} />,
  router: {
    abi: routerAbi,
    address: '0x1D3a30c894F581F0b7295E1bdBED268818318c13',
  },
  rpcUrls: {
    default: {
      // http: ['https://rpc.ankr.com/eth'],
      http: ['https://ethereum-rpc.publicnode.com'],
    },
    public: {
      // http: ['https://rpc.ankr.com/eth'],
      http: ['https://ethereum-rpc.publicnode.com'],
    },
  },
  vault: {
    address: '0xF09c6F088057A2EB11AEF885640F0440F3710f8b',
  },
  tokens: {
    usdt: { address: '0xdAC17F958D2ee523a2206206994597C13D831ec7', abi: usdtOldAbi },
    // usdc: { address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48' },
  },
};
