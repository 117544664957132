import { getImageChain } from 'entities/Chain';

import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const bsc: AppChain<'bsc'> = {
  blockExplorers: {
    default: {
      name: 'BscScan',
      url: 'https://bscscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  disabled: false,
  id: 56,
  logo: <CurrencyIcon url={getImageChain('bsc')} ticker="bnb" size={24} />,
  name: 'BNB Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  network: 'bsc',
  tokenStandard: 'BEP-20',
  renderLogo: (size) => <CurrencyIcon url={getImageChain('bsc')} ticker="bnb" size={size} />,
  router: {
    abi: routerAbi,
    address: '0x1D3a30c894F581F0b7295E1bdBED268818318c13',
  },
  rpcUrls: {
    default: {
      // http: ['https://rpc.ankr.com/bsc'],
      http: ['https://bsc-dataseed3.binance.org'],
    },
    public: {
      // http: ['https://rpc.ankr.com/bsc'],
      http: ['https://bsc-dataseed3.binance.org'],
    },
  },
  vault: {
    address: '0xF09c6F088057A2EB11AEF885640F0440F3710f8b',
  },
  tokens: {
    usdt: { address: '0x55d398326f99059fF775485246999027B3197955' },
    // usdc: { address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d' },
  },
};
