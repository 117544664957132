import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PathPatterns } from 'shared/lib';
import { Box } from 'shared/ui/Box';
import { Logo } from 'shared/ui/Logo';

export const NavLogo: FC = () => {
  const navigate = useNavigate();

  const handlePress = useCallback(() => {
    navigate(PathPatterns.DualsDashboard);
  }, [navigate]);

  return (
    <Box onClick={handlePress}>
      <Logo size={40} />
    </Box>
  );
};
